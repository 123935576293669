<template>
    <div id="message-dialog" class="modal fade a-dialog success-confirmation" tabindex="-1" role="dialog" style="z-index: 1051">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content a-dialog-content">
                <div class="modal-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components:{
        // ArButton : ()=> import('./Button')
    },
    name: 'ar-message-dialog',
}
</script>

<style scoped>
    /* .a-dialog {
        display: block; /* remove in production
        position: relative /* remove in production 
    } */

    .a-dialog-content {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 2px #00000029;
        border: 1px solid #5153655E;
        border-radius: 6px;
        text-align: center;
        font-size: 16px;
        padding: 35px;
        opacity: 1;
    }

    .a-dialog-header {
        color: #515365;
        font-weight: 400;
        font-size: 24px;
    }

    .modal::before {
        content:"";
        background: rgba(0, 0, 0, 0.3);
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;
    }

    .success-confirmation img {
        width: 180px;
        height: auto;
        top: -25px;
        position: absolute;
    }
</style>