<template>
        <main class="main-con">
            <div class="content">
                <img src="/static/svg/Under Maintenance.svg" alt="Under Maintenance">
            </div>
        </main>
</template>

<script>
export default {
    name: 'maintenance',
}
</script>

<style scoped>
    .main-con {
        min-height: 100vh;
    }

    .content {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        position: absolute;
        width: 43%;
        left: 50%;
        top: 45%;
    }

    .content img {
        width: 100%;
        display: block;
        margin: 0 auto;
    }
</style>