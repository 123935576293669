<template v-once>
    <div 
        class="a-container" 
        :class="{
            'grey-bg': grey_bg,
            'gui-admin': $route.path.includes('admin'),
            'gui-ver': $route.path.includes('verification'),
            'gui-cm': $route.path.includes('lawfirm')
        }">

        <div v-show="show_navbar" class="a-navcon">
            <ar-navbar
                :gui  ="getGui()"
                :type ="navbar_type"
                :user ="getName()"
                :exit ="getGui()"
                :notifications ="notifications"
                :key="navbarKey"
                ref="navbarRef"
            >
                <template v-slot:header-buttons>
                    <template v-if="$route.name == 'manualaddition'">
                        <ar-button
                            v-show    ="!edit_listing" 
                            class="mr-2 btn"
                            outlined
                            @click="backToListing">
                            Cancel
                        </ar-button>
                        <ar-button 
                            v-show    ="!edit_listing" 
                            data-toggle="modal"
                            data-target="#confirm-add-listing-modal"
                            :disabled ="$store.state.is_disable">
                            Add this listing
                        </ar-button>
                    </template>
                    <template v-else>
                        <ar-button class="mr-2" outlined v-show="$route.path.includes('admin') && !['admineditlawfirm', 'admineditcampaign'].includes($route.name)" @click="cancelEdit">Cancel</ar-button>
                        <ar-button 
                            v-show    =" !edit_listing && !['admineditlawfirm', 'admineditcampaign'].includes($route.name)"
                            @click    ="saveAndExit" 
                            :disabled ="!exit_manual_addition">
                            <img src="/static/svg/manual-add/Save.svg" class="position-relative" style="margin-right: 8px; top: -2px" alt="icon">
                            Save and Exit
                        </ar-button>
                        
                        <ar-button class="mr-2" outlined v-show="edit_listing" @click="cancelEdit" :disabled="!$store.state.is_active_list_ready">Cancel</ar-button>
                        <ar-button v-if="$store.state.is_active_list_ready && !manual_addition_errors.length" v-show="edit_listing" id="save-and-exit" @click="saveAndExit">
                            <img src="/static/svg/manual-add/Save.svg" class="position-relative" style="margin-right: 8px; top: -2px" alt="icon">
                            Save and Exit
                        </ar-button>
                        <ar-button v-else v-show="edit_listing" id="save-and-exit" :disabled="true" @mouseover="showExitMessage">
                            <img src="/static/svg/manual-add/Save.svg" class="position-relative" style="margin-right: 8px; top: -2px" alt="icon">
                            Save and Exit
                        </ar-button>
                    </template>
                </template>
            </ar-navbar>
            <ar-filter 
                v-show          ="show_filterdrawer"
                @changeRadio    ="getRadioValue" 
                @changeCheckbox ="getCheckboxValue" 
                :statuses       ="getStatuses()" 
                @mouseover      ="showScroll()" 
                @mouseleave     ="hideScroll()"
            />
        </div>

        <ar-sidebar
            v-show="show_sidebar"
            :gui="getGui()"
        />

        <ar-snackbar
            v-show=" (snackbar || {}).text || false"
            :type=" (snackbar || {}).type || null"
            :icon=" (snackbar || {}).icon || 'fas'"
            :text=" (snackbar || {}).text || null"
            :user=" (snackbar || {}).user || ''"
            :timestamp=" (snackbar || {}).timestamp || 'just now'"
        />

        <ar-backdrop :data="backdrop"/>

        <ar-confirm></ar-confirm>

        <client-listings
            :type="access_level == 5 ? 'forCL' : 'forResearcher&QA'"
            v-restrict="'v-modal-client-listings'"
            class="v-modal-client-listings"
        ></client-listings>
        <auto-captured-lfc></auto-captured-lfc>
        <deployment-confirmation ref="deploymentConfirmationRef"></deployment-confirmation>

        <portal>
            <ArDuplicateSellerModal :duplicates="relatedCampaignDuplicates"/>
        </portal>
        
        <router-view
            v-if="['lawfirmlisting'].includes($route.name)"
            :ref="reference"
            :key="`${$route.params.campaign_id}-${$route.params.status}`"
        ></router-view>
        <router-view
            v-else
            :ref="reference"
            :key="view_key"
        ></router-view>
    </div>
</template>

<script>
    const component = (view) => () => import(`./${view}`);
    import axios from '../../axiosMime';
    import {mapState, mapActions, mapGetters} from 'vuex';
    import { useI18n } from '../../utils/i18n';
    import { useRouteRestriction } from '../../utils/route';
    import { useFeatureAccessEvent } from '../../events/feature-access'
    import { useFeatureAccessStore } from '../../store/pinia/feature-access'
    import { initializeSocket, listen as globalListen, send as globalSend, listen as listenNewUpdatedCampaign, listen as listenNewDeployment } from '../../events';
    import { listen } from '../../events/exhausted-assignment-logs'
    import { listenNewDisabledCombo } from '../../events/assignments'

    export default {
        name: 'ar-container',
        components: {
    'ar-sidebar': component("Sidebar"),
    'ar-navbar': component("Navbar"),
    'ar-filter': component("FilterDrawer"),
    'ar-button': component("Button"),
    'ar-snackbar': component("Snackbar"),
    'ar-backdrop': component("Backdrop"),
    'client-listings': component("ClientListings"),
    'auto-captured-lfc': component("AutoCapturedLFC"),
    'ar-confirm': () => import('../layouts/ConfirmDialog'),
    'ArDuplicateSellerModal': () => import("../layouts/modals/DuplicateSellerModal"),
    'deployment-confirmation': component("DeploymentConfirmation"),
},
        computed:{
            ...mapState([
                'seller_reference', 'listing_reference', 'show_navbar',
                'show_sidebar', 'show_filterdrawer', 'navbar_type',
                'manually_added', 'exit_manual_addition', 'grey_bg',
                'active_listing_info', 'manual_addition_errors', 'view_key', /*'client-listings', Commented because no variable in index.js - vuex */
                'previous_route', 'client_Listings', 'Listing_has_clientListings',
                'hasTriggeredSomething','show_clientListings', 'relatedCampaignDuplicates',
                'paymentAccountsToBeAdded'
            ]),//Accessing Vuex State
            ...mapGetters(['getPaymentAccountsToBeAdded']),
            edit_listing(){
                return this.$route.query.action == "edit" ? true : false 
            },
            isCM() {
                return this.$route.path.includes('/lawfirm')
            },
            isAdmin() {
                return this.$route.path.includes('/admin')
            },
            canProceedListingUpdateOnCM() {
                let listingInfoComponent = 'stuff'
                return true
            },
            refs() {
                let data = {}
                const iRef = this.$refs

                if (this.isCM) {
                    let temp = iRef[""].$children[0]
                    data.seller_info = temp.$refs.sellerinfo['seller_info']
                    data.seller_location = temp.$refs.sellerinfo.$refs["sellerlocation"]["sellerlocation"]
                    data.listing_info = temp.$refs.listinginfo["listing_info"]
                    data.listing_price = temp.$refs.listinginfo.$refs["listingprice"]["listing_info"]
                    data.transact_history = temp.$refs.listinginfo["seller_info"]["transact_history"]
                    data.listingpaymentaccount = temp.$refs.listinginfo.$refs["listingpaymentaccount"]
                } else {
                    data.seller_info = iRef.listinginfo.$refs.sellerinfo["seller_info"]
                    data.seller_location = iRef.listinginfo.$refs.sellerinfo.$refs["sellerlocation"]["sellerlocation"]
                    data.listing_info = iRef.listinginfo.$refs.listinginfo["listing_info"]
                    data.listing_price = iRef.listinginfo.$refs.listinginfo.$refs["listingprice"]["listing_info"]
                    data.transact_history = iRef.listinginfo.$refs.listinginfo["seller_info"]["transact_history"]
                    data.listingpaymentaccount = iRef.listinginfo.$refs.listinginfo.$refs['listingpaymentaccount']
                }

                return data
            },
            returnRoute() {
                let gui = this.isCM ? 'lawfirm' : 'ver'

                /* let payload = {
                    type: this.access_level == 5 ? 'byCampaignLead' : '',
                    gui: gui,
                    user_id: this.user_id,
                } */

                let payload = {
                    type: this.showClientListingsModal ? 'byCampaignLead' : '',
                    gui: gui,
                    user_id: this.user_id,
                }

                let route = ''
                if(!this.isCM) {
                    this.$store.dispatch('setListing_has_clientListings', payload)

                    route = {name:"listing",params:{campaign_id:this.$route.params.campaign_id}, query: { status: this.previous_route?.query?.status == '' || this.previous_route?.query?.status == null ? this.getQFlag(this.$route.query.qflag_id) : this.previous_route?.query?.status/*this.getQFlag(this.$route.query.qflag_id)*/, platform: 0 , /*keywords:this.$route.query.lid*/ }}
                    
                    if(this.previous_route?.name == 'task') {
                        route = {name: this.previous_route.name}
                    }

                    if(this.Listing_has_clientListings && this.access_level == 1) {
                        route = {name:"listing",params:{campaign_id:this.$route.params.campaign_id}, query: { status: 'Client Listing', platform: 0 }}
                    }

                }

                return this.isCM
                    ? {name:"lawfirmlisting",params:{campaign_id:this.$route.params.campaign_id, status: this.getQFlag(this.$route.query.qflag_id), case_id: 0}, query: { lid: this.$route.query.lid }}
                    /**
                     * Deliverable: Remove listing id from filter after edit listing
                     * Deliverable Number: 465
                     */
                    // : this.$router.back()
                    // : this.previous_route?.name == 'task'? {name: this.previous_route.name} : {name:"listing",params:{campaign_id:this.$route.params.campaign_id}, query: { status: this.previous_route?.query?.status == '' || this.previous_route?.query?.status == null ? this.getQFlag(this.$route.query.qflag_id) : this.previous_route?.query?.status/*this.getQFlag(this.$route.query.qflag_id)*/, platform: 0 , /*keywords:this.$route.query.lid*/ }}
                    : route
            },
            access_level(){
                /* let access_level = 0
                if(location.pathname.includes("verification")){
                    access_level = JSON.parse(localStorage.getItem('ver')).access_level
                }
                if (location.pathname.includes("lawfirm")) {
                    access_level = JSON.parse(localStorage.getItem('lawfirm')).access_level
                }
                if (location.pathname.includes("admin")) {
                    access_level = JSON.parse(localStorage.getItem('admin')).access_level
                } */
                
                const includes= gui => location.pathname.includes(gui)
                const access = includes('verification') 
                        ? 'ver'
                        : includes('lawfirm')
                            ? 'lawfirm'
                            : 'admin';

                return JSON.parse(localStorage.getItem(access))?.access_level
            },
            config() {
                /* let config = {}
                if(location.pathname.includes("verification")){
                    config = JSON.parse(localStorage.getItem('ver'))
                }
                if (location.pathname.includes("lawfirm")) {
                    config = JSON.parse(localStorage.getItem('lawfirm'))
                }
                if (location.pathname.includes("admin")) {
                    config = JSON.parse(localStorage.getItem('admin'))
                }
                return config */

                const includes= gui => location.pathname.includes(gui)
                const access = includes('verification') 
                        ? 'verification'
                        : includes('lawfirm')
                            ? 'lawfirm'
                            : 'admin';

                return JSON.parse(localStorage.getItem(access))
            },
            /**
             * @return {string}
             */
            reference(){
                return this.$route.name == "listinginfo" ? "listinginfo" : ""
            },
            user_id() {
                return JSON.parse(localStorage.getItem(this.isCM ? 'lawfirm' : this.isAdmin ? 'admin' : 'ver'))?.id
            },
            showClientListingsModal() {
                return !this.isFeatureRestricted('v-modal-client-listings');
            },
            locale() {
                return vm.locale;
            },
            restrictedFeatures() {
                return useFeatureAccessStore().restrictedFeatures;
            },
            routeGUI() {
                const gui = this.$route.path.split('/')[1];
                return gui;
            },
            showResearcherTrackerModal() {
                return !this.$featureAccess.isRestricted('v-enable-auto-assignment') || !this.isWeeklyQuotaTrackerRestricted && !['listing', 'search'].includes(this.$route.name)
            },
            isWeeklyQuotaTrackerRestricted() {
                return this.$featureAccess.isRestricted('v-enable-weekly-quota-tracker')
            },
            showWeeklyQuotaTracker() {
                return !this.isWeeklyQuotaTrackerRestricted && ['listing', 'search'].includes(this.$route.name)
            },
        },
        data() {
            return {
                gui              : 'Verification',
                has_titlebar     : true,
                can_status       : true,
                sidebar_campaigns: [],
                statuses         : ["Work in Progress", "Recheck", "Reworked", "Researched", "Qualified", "Monitoring", "Accepted", "Rejected", "Filed", "Invalid", "Has Potential"],
                timers           : [],
                b_timers         : [],
                snackbar         : { text: '', icon   : '', type: '', closable: false, user: '', time: '' },
                backdrop         : { show: false, text: '', hide: true },
                notifications    : [{
                    icon: "/static/svg/notifications/Qualified.svg",
                    user: "Joe June Labajo",
                    timestamp: "1 hour",
                    details: `Marked your <span style="color: rgb(45, 89, 158);"><b>Researched</b></span> listing <b>1789214545</b> to <span style="color: rgb(41, 171, 162);"><b>Qualified</b></span>`,
                    campaign: "Sample Campaign"
                }],
                navbarKey: this.getRandomKey(),
                isRequestingNotification: false,
            }
        },
        watch:{
            showResearcherTrackerModal(val) {
                if (!val) return

                this.navbarKey = this.getRandomKey()
            },
            showWeeklyQuotaTracker(val) {
                if (!val) return

                this.navbarKey = this.getRandomKey()
            },
            relatedCampaignDuplicates: {
                handler() {
                    this.showRelatedCampaignDuplicateModal()
                },
                deep: true
            },
            locale (value) {
                this.$i18n.locale = value;
                localStorage.setItem('locale', value);
            },
            '$route.name'(new_name, old_name){
                if ($('.modal'))
                {
                    $('.modal').modal('hide');
                }
                this.clearTimers()
                this.clearBTimers()
                document.title     = this.$route.meta.title
                document.body.style.paddingRight = ""
                this.snackbar.text = ""
                this.hideBackdrop()
                this.getFeatureAccess()

                if (["listinginfo", "manualaddition", "admineditcampaign", "admineditlawfirm", "lawfirmeditlisting"].includes(this.$route.name)) {
                    this.changeNavbarType('addition')
                } else {
                    this.changeNavbarType('main')
                    this.changeAddedType(false)
                }

                switch(this.$route.name){
                    case 'verification':
                        this.showSidebar(false)
                        this.showNavbar(false)
                        this.showFilterDrawer(false)
                        this.greyBg(false)
                    break;
                    case 'task':
                    case 'user-profile-ver':
                    case 'user-profile-lf':
                    case 'user-profile-admin':
                    case "lawfirmdashboard": // Added Dashboard for CM
                        this.showSidebar(true)
                        this.showNavbar(true)
                        this.showFilterDrawer(false)
                        this.greyBg(true)
                    break;
                    case "campaigns":
                    case "admindashboard":
                    case "notifications":
                    case "lawfirmnotifications":
                    case "adminnotifications":
                    case "admincampaignaccounting":
                    case "adminresearchersoutput":
                    case "adminpaymentreports":
                    case "admincampaignreport":
                    case "admindatacheckerreport":
                    case "adminaccountingreports":
                    case "lawfirmcampaigncounterfeitchecker":
                    case "lawfirmcasefilesexplorer":
                    case "lawfirmsubmissionspackmonitoring":
                    case "admin3monthreport":
                    case "auto-capture-listing":
                    case "admininfographicreport":
                    case "adminhistorylogs":
                    case "adminautocampaignassignment":
                    case "adminautocampaignassignmentweeklyquota":
                    case "adminautocapturemanagement":
                    case "admindeploymentpage":
                        this.showSidebar(true)
                        this.showNavbar(true)
                        this.showFilterDrawer(false)
                        this.greyBg(true)
                    break;
                    case "newlisting":
                        this.showSidebar(false)
                        this.showNavbar(false)
                        this.showFilterDrawer(false)
                        this.greyBg(false)
                    break;
                    case "lawfirmcasefilesexplorerall":
                        this.showSidebar(false)
                        this.showNavbar(false)
                        this.showFilterDrawer(false)
                        this.greyBg(true)
                    break;
                    case "search":
                        this.showSidebar(true)
                        this.showNavbar(true)
                        this.showFilterDrawer(true)
                        this.greyBg(true)
                    break;
                    case "lawfirmlisting":
                    case "lawfirmlistingv2":
                    case "lawfirmcases":
                    case "lawfirmcaselistings":
                    case "listing":
                    case "listings":
                    case "adminrightsholder":
                    case "adminrightsholdercampaigns":
                    case "admincampaigns":
                    case "adminplatforms":
                    case "adminsellers":
                        this.showFilterDrawer(true)
                        this.showNavbar(true)
                        this.showSidebar(true)
                        this.greyBg(true)
                    break;
                    case "adminwhitelists":
                    case "adminlawfirms":
                    case "adminlfusers":
                    case "adminartemisusers":
                    case "adminFeatures": //Admin Features
                    case "adminUserRestrictions": //Admin Features
                    case "adminRoleBasedRestrictions": //Admin Features
                    case "adminteams":
                    case "adminreasons":
                    case "admindummies":
                    case "admindatapriority":
                    case "adminpaymentmethods":
                    case "adminshippinglocations":
                    case "adminterritory":
                    case "admininfringementindicators":
                    case "admindefaultevidenceorder":
                    case "adminenvaccessmanagement":
                    case "admindefaultshippinglocation":
                    case "admindefaultclaimtype":
                    case "adminweeklyreport":
                    case "admincampaignweeklyreport":
                    case "lawfirmcounterfeitchecker": // commented out, not needed yet Dev: Marvin - 02/01/2021
                        this.showNavbar(true)
                        this.showSidebar(true)
                        this.greyBg(true)
                        this.showFilterDrawer(true)
                    break;
                    case "adminsellerlistings":
                    case "adminitemcampaigns":
                    case "adminitemcampaigns_classification":
                    case "adminitemcampaigns_itemtypes":
                    case "adminitemclassification":
                    case "adminitemclassification_itemtypes":
                    case "adminitemtypes":
                        this.showNavbar(true)
                        this.showSidebar(true)
                        this.greyBg(true)
                        this.showFilterDrawer(true)
                    break;
                    case "adminsupervisors":
                        this.showSidebar(true)
                        this.showNavbar(true)
                        this.showFilterDrawer(true)
                        this.greyBg(true)
                    break;
                    case "listinginfo":
                    case "admineditcampaign":
                    case "admineditlawfirm":
                    case "lawfirmeditlisting":
                        this.showSidebar(false)
                        this.showNavbar(true)
                        this.showFilterDrawer(false)
                        this.greyBg(false) 
                    break;
                    case "manualaddition":
                        this.showSidebar(false)
                        this.showNavbar(true)
                        this.showFilterDrawer(false)
                        this.greyBg(false) 
                    break;
                    default:
                        this.showNavbar(false)
                        this.showFilterDrawer(false)
                        this.showSidebar(false)
                        this.changeNavbarType('main')
                        this.changeAddedType(false)
                        this.greyBg(false)
                }

                
            },
            '$route.path'(val) {
                if(!val.includes('/verification')) {
                    this.$store.dispatch('showClientListings', false)
                }

                /**
                 * Deliverable: Remove listing id from filter after edit listing
                 * Deliverable Number: 465
                 * 
                 * Serves as a router guard, can add more security inside this function
                 */
                this.$router.beforeEach((to, from , next) => {
                    // Store previous route from router
                    this.$store.dispatch('getPrev_route', from)
                    // continue the route being pushed
                    next()
                })
            },
            'hasTriggeredSomething'(val) {
                if(val) {
                    // Added trigger for checking if there are Clients from Listings 
                    
                    let gui = this.isCM ? 'lawfirm' : this.isAdmin ? 'admin' : 'ver'
                    let payload = {
                        type: this.access_level == 5 ? 'byCampaignLead' : '',
                        gui: gui,
                        user_id: this.user_id,
                    }
                    this.$store.dispatch('setListing_has_clientListings', payload)
                }
            },
            'client_Listings'(val) {
                if(val.length <= 0) {
                    this.$store.dispatch('showClientListings', false)
                    // console.log(val, val.length)
                }
            },
            showClientListingsModal() {
                $('.modal').modal('hide');
            },
            restrictedFeatures() {
                useRouteRestriction();
            },
            $route: {
                handler() {
                    useRouteRestriction();
                },
                deep: true
            }
        },
        async created() {
            useFeatureAccessEvent();
            await initializeSocket();

            this.$i18n.locale = '';
            await useI18n();
            this.$i18n.locale = vm.locale;

            document.title     = this.$route.meta.title
            if(this.$route.name == "listinginfo" || this.$route.name == "manualaddition"){
                this.changeNavbarType('addition')
            }else{
                this.changeNavbarType('main')
                this.changeAddedType(false)
            }
        },
        async mounted() {
            listen(data => {
                // $('#lead-exhausted-tracker').modal('show');

                this.$refs.navbarRef.newExhausted.details = data
                this.$refs.navbarRef.newExhausted.status = true
                this.$refs.navbarRef?.$refs?.leadtrackerRef?.getExhaustedCombos()
            })

            // this.$toast.success("Detected new changes, reloading..", { position: 'top-right', timeout: 10000, closeOnClick: true, showCloseButtonOnHover: true, pauseOnFocusLoss: false, pauseOnHover: false, icon: 'fas fa-flask'})

            listenNewDisabledCombo(data => {
                console.log(data)
                this.$refs?.navbarRef?.$refs?.leadtrackerRef?.getExhaustedCombos()
            })

            listenNewDeployment(data => {
                if(data.type == 'newDeployedChanges') {
                    if(data.data) {
                        setTimeout(() => {
                            // perform the showing of the snackbar code here
                            this.$refs.deploymentConfirmationRef.hasNewDeployedChanges = true
                            this.$refs.deploymentConfirmationRef.openModal('deployment-confirmation')
                        }, 500)
                    } else {
                        // this.hasNewDeployedChanges = false
                        this.$refs.deploymentConfirmationRef.closeModal('deployment-confirmation')
                        this.$refs.deploymentConfirmationRef.hasNewDeployedChanges = false
                    }
                }
            })

            this.removeFade()

            if (this.$route.path.includes('search')) {
                this.statuses.unshift('All')
            }
           
            this.tokenChecker()
            ?.then(async is_valid_token => {
                await sleep(500)
                if (is_valid_token && this.$route.path.includes('/verification')) {
                    this.getNotifications();
                    this.startNotificationListener(true);
                    this.startNotificationListenerSocket();
                }
            });

            // refreshes the page, but only in verification

            let gui = this.isCM ? 'lawfirm' : this.isAdmin ? 'admin' : 'ver'

            /* let payload = {
                type: this.access_level == 5 ? 'byCampaignLead' : '',
                gui: gui,
                user_id: this.user_id,
            } */

            let payload = {
                type: this.showClientListingsModal ? 'byCampaignLead' : '',
                gui: gui,
                user_id: this.user_id,
            }

            if(gui == 'ver') {
                await this.$store.dispatch('setListing_has_clientListings', payload)
            }

            //TODO: Pinia Store will be used and disabled for now.
            // await this.$store.dispatch('featureAccessManagement/getFeatures')
            // await this.$store.dispatch('featureAccessManagement/getUserRestrictedFeatures')
        },
        updated() {
            this.removeFade()
        },
        methods: {
            ...mapActions(['showSidebar','showNavbar','showFilterDrawer','changeNavbarType','changeAddedType','showListingInfo','showEvidence','greyBg', 'getNotifications', 'startNotificationListener', 'getFeatureAccess', 'updatePaymentAccountsToBeAdded']),
            startNotificationListenerSocket() {
                globalListen(async (message) => {
                    if (message.type !== 'notification') {
                        return;
                    }

                    const { data } = message

                    if (!this.isRequestingNotification && (this.user_id && [data.researcher_id, data.qa_user_id, data.updater_id].includes(this.user_id) || this.access_level == 5) && this.$route.path.includes('verification')) {
                        this.isRequestingNotification = true
                        await this.startNotificationListener()
                        await sleep(5000)
                        this.isRequestingNotification = false
                    }
                }) 
            },
            showRelatedCampaignDuplicateModal() {
                $('#related-campaign-duplicate-sellers-modal').modal('show')
            },
            getStatuses(){
                if(this.getGui() === 'Lawfirm'){
                    return [
                        'Has Potential',
                        'For Review',
                        'Monitoring',
                        'Accepted',
                        'Rejected',
                        'Cases'
                    ]
                }
                return this.statuses
            },  
            getGui(){
                var string =  location.pathname.split("/")[1]
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            getName(){
                let name = ""
                if (localStorage.getItem("ver") != null && location.pathname.includes("verification")){
                    name = JSON.parse(localStorage.getItem('ver')).first_name+" "+(JSON.parse(localStorage.getItem('ver')).last_name != null ? JSON.parse(localStorage.getItem('ver')).last_name : "")
                } else if (localStorage.getItem("lawfirm") != null && location.pathname.includes("lawfirm")){
                    name = JSON.parse(localStorage.getItem('lawfirm')).first_name+" "+(JSON.parse(localStorage.getItem('lawfirm')).last_name != null ? JSON.parse(localStorage.getItem('lawfirm')).last_name : "")
                } else if (localStorage.getItem("admin") != null && location.pathname.includes("/admin")) {
                    name = JSON.parse(localStorage.getItem('admin')).first_name + " " + (JSON.parse(localStorage.getItem('admin')).last_name != null ? JSON.parse(localStorage.getItem('admin')).last_name : "")
                }
                return name
            },
            showScroll() {
                document.getElementsByClassName('sidebar')[0].style.overflowY = "auto";
            },
            hideScroll() {
                document.getElementsByClassName('sidebar')[0].style.overflowY = "hidden";
            },
            getRadioValue(value){
                alert(value)
            },
            getCheckboxValue(value){
                if(!this.filtered_platforms.includes(value)){
                    this.filtered_platforms.push(value)
                }else{
                    this.filtered_platforms = this.filtered_platforms.filter(platform=>{return platform !== value})
                }
                alert(this.filtered_platforms.toString())
            },
            gotoTask() {
                this.$router.push({name: 'tasks'})
            },
            gotoCampaign() {
                this.$router.push({name: 'campaigns'})
            },
            removeFade() {
                setTimeout(() => {
                    var $element = document.querySelector(".animated.fadeIn.faster")

                    if (typeof $element != 'undefined' && $element != null) $element.classList.remove("animated", "fadeIn", "faster")
                })
            },
            /*
            * Call to show snackbar - Dev: Marvin 12/14/2019
            */
            showSnackbar(text = null, loading = false, type = "default", icon = "/static/img/V-Loader.gif", closable = true, user = "", time = "just now") {
                this.snackbar.icon = icon
                this.snackbar.type = type
                this.snackbar.text = text
                this.snackbar.user = user
                this.snackbar.time = time
                this.snackbar.closable = closable

                this.clearTimers()

                setTimeout(_ => {
                    var _snackbar = document.getElementsByClassName('a-snackbar')[0]                    
                    // var _newSnackbar = document.getElementsByClassName('a-snackbar2')[0]                    
                    if (_snackbar != null && !_snackbar.classList.contains('show')) _snackbar.classList.add('show')
                    // if (_newSnackbar != null && !_newSnackbar.classList.contains('show')) _newSnackbar.classList.add('show')
                }, 500)
                
                if (!loading) {
                    this.timers.push(setTimeout(_ => {
                        var _snackbar = document.getElementsByClassName('a-snackbar')[0];
                        var _newSnackbar = document.getElementsByClassName('a-snackbar2')[0];

                        if (_snackbar != null) {
                            _snackbar?.classList?.remove('bounceInLeft')
                            _snackbar?.classList?.add('bounceOutLeft')
                        }

                        if (_newSnackbar != null) {
                            _newSnackbar?.classList?.remove('bounceInUp')
                            _newSnackbar?.classList?.add('bounceOutDown')
                        }

                        this.timers.push(setTimeout(() => {
                            this.snackbar.text = ""
                            this.snackbar.icon = ""
                            this.snackbar.type = ""
                        }, 500))
                    }, 5000))
                }
            },
            /*
            * Call to hide snackbar - Dev: Marvin 12/14/2019
            */
            hideSnackbar() {
                this.clearTimers()
                
                this.timers.push(setTimeout(() => {
                    var _snackbar = document.getElementsByClassName('a-snackbar')[0];
                    var _newSnackbar = document.getElementsByClassName('a-snackbar2')[0];
                    
                    if (_snackbar != null) {
                        _snackbar?.classList?.remove('bounceInLeft')
                        _snackbar?.classList?.add('bounceOutLeft')
                    }

                    if (_newSnackbar !== null) {
                        _newSnackbar?.classList?.remove('bounceInUp')
                        _newSnackbar?.classList?.add('bounceOutDown')
                    }

                    this.timers.push(setTimeout(() => {
                        this.snackbar.text = ""
                    }, 500))
                }))
            },
            clearTimers() {
                for( var i = 0; i < this.timers.length; i++) {
                    clearTimeout(this.timers[i])
                }
            },
            clearBTimers() {
                for( var i = 0; i < this.b_timers.length; i++) {
                    clearTimeout(this.b_timers[i])
                }
            },
            showBackdrop() {
                document.body.classList.add('modal-open')
                this.backdrop.hide = false
                this.backdrop.show = true

                this.clearBTimers()
            },
            hideBackdrop() {
                document.body.classList.remove('modal-open')
                this.backdrop.show = false
                this.b_timers.push(setTimeout(() => {
                    this.backdrop.hide = true
                }, 500))
            },
            async validateFieldsNotEmpty() {
                return new Promise(resolve => {
                    let invalidCounter = 0
                    const container = document.querySelector('main.main-con')
                    const chipTextfields = container.querySelectorAll('.a-textfield:not(.except-me) .custom-chip-textfield')
                    const textfields = container.querySelectorAll('.a-textfield:not(.except-me):not(.purchase-type):not(:has(+ .search-select-options-con)) div:not(.custom-chip-textfield) input')
                    const textareas = container.querySelectorAll('.a-textfield:not(.except-me) textarea')
                    const dateCaptured = container.querySelector('#date-captured')
                    const dropdowns = container.querySelectorAll('.a-select:not(.except-me):not(.purchase-type) select')
                    const dropdownSearch = container.querySelectorAll('.a-select:not(.except-me) .a-textfield:has(+ .search-select-options-con) input')
                    const dropdownPurchaseType = [...container.querySelectorAll('.a-select.purchase-type:not(.except-me) select'), ...container.querySelectorAll('.a-textfield.purchase-type:not(.except-me) input')]
                    const purchase_type = dropdownPurchaseType.find(element => element.id == 'purchase_type')

                    const fields = [...chipTextfields, ...textfields, ...textareas, ...dropdowns, dateCaptured];

                    const isElementRestricted = (/** @type { Element } */ element) => {
                        const featureName = element?.closest('div[feature-name]')?.getAttribute('feature-name');

                        if (this.$featureAccess.isAllowed(featureName) || featureName == null) {
                            return false;
                        }

                        element.classList.remove('is-invalid');
                        return true;
                    }

                    fields.forEach(element => {
                        if (isElementRestricted(element)) {
                            return;
                        }

                        const condition = ['0', ''].includes(element.value) || (element.classList.contains('custom-chip-textfield') && element.children[0].children.length == 0)
                        element.classList[condition ? "add" : "remove"]('is-invalid')

                        if (condition) invalidCounter++
                    });


                    dropdownSearch.forEach(element => {
                        if (isElementRestricted(element)) {
                            return;
                        }

                        let current = element
                        let currentCounter = 0
                        while (!current.classList.contains('a-select') && currentCounter < 10) {
                            current = current["parentElement"]
                            currentCounter++
                        }

                        const condition = current.classList.contains('a-select') && current.getAttribute("value") == 0
                        element.classList[condition ? "add" : "remove"]('is-invalid')

                        if (condition) invalidCounter++
                    })

                    if (purchase_type) {
                        if (purchase_type.value == 0) {
                            if (!isElementRestricted(purchase_type)) {
                                purchase_type.classList.add('is-invalid')
                                invalidCounter++
                            }

                        } else if (['Purchased and shipped', 'Delivery delayed', 'Delivery failed/unsuccessful', 'Delivered'].includes(purchase_type.value)) {
                            dropdownPurchaseType.forEach(element => {
                                if (!isElementRestricted(element)) {
                                    const condition = ['0', '', null].includes(element.value)
                                    element.classList[condition ? "add" : "remove"]('is-invalid')

                                    if (condition) invalidCounter++
                                }
                            })
                        } else {
                            purchase_type.classList.remove('is-invalid')
                        }
                    }

                    resolve(invalidCounter > 0)
                });
            },
            async saveAndExit(type = 'multi') {
                if (this.isCM) {
                    let daListingInfo = this.$children.filter(c => c.$vnode.tag.includes('cm-edit-listing'))[0].$children[0]
                    let daEvidences = daListingInfo.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-listinginfo')[0].$children.filter(c => c.$vnode.componentOptions.tag == 'ar-evidence')[0].uploaded_evidences
                    let requiredEvidences = { ...daListingInfo.required_fields.Evidences }
    
                    for (let rIndex = 0; rIndex < daEvidences.length; rIndex++) {
                        const getEvidenceKey = (key) => {
                            let evidenceKey = ""
                            if (key.toLowerCase().includes('lf') && key.toLowerCase().includes('review'))
                                evidenceKey = 'LF Review'
                            else if (key.toLowerCase().includes('preview'))
                                evidenceKey = 'Preview'
                            else if (key.toLowerCase().includes('product'))
                                evidenceKey = 'Product'
                            else if (key.toLowerCase().includes('store'))
                                evidenceKey = 'Store'
    
                            return evidenceKey
                        }
                        
                        requiredEvidences[getEvidenceKey(daEvidences[rIndex].type)] = daEvidences[rIndex].image != '' && daEvidences[rIndex].image != null
                    }
    
                    let daRequired_fields = [...Object.entries(daListingInfo.required_fields.Data)]
    
                    for (let rIndex = 0; rIndex < daRequired_fields.length; rIndex++) {
                        let current_field = daRequired_fields[rIndex]
                        
                        if (!current_field[1]) {
                            this.showSnackbar(current_field[0] + " is missing, please fill-up to continue listing update.",false,'invalid','fas fa-exclamation-triangle')
                            return
                        }
                    }

                    let daRequired_evidences = [...Object.entries(requiredEvidences)]
    
                    for (let rIndex = 0; rIndex < daRequired_evidences.length; rIndex++) {
                        let current_field = daRequired_fields[rIndex]
                        
                        if (!current_field[1]) {
                            this.showSnackbar(current_field[0] + " is missing, please fill-up to continue listing update.",false,'invalid','fas fa-exclamation-triangle')
                            return
                        }
                    }
                }

                let config          = JSON.parse(JSON.parse(localStorage.getItem(this.isCM ? 'lawfirm' : 'ver')).headers)
                config.headers["Campaign-id"] = this.$route.params.campaign_id
                let seller_info     = {...this.refs.seller_info,...this.refs.seller_location}
                let listing_info    = {...this.refs.listing_info,...this.refs.listing_price}
                let seller_data = {"sellers":{
                    [seller_info.seller_id]:{
                        "name"       : seller_info.name,
                        "url"        : seller_info.url.url.trim(),
                        "platform_id": seller_info.platform_id,
                        "site_id"    : seller_info.site_id,
                        "contactable": seller_info.contactable,
                        // "campaign_id": this.$route.params.campaign_id,
                        "campaign_id": seller_info.campaign_id,
                        "info"       : {
                                // "alias"             : seller_info.alias,
                                // "alias"             : seller_info.alias.toString(),
                                "contact_person"    : seller_info.contact_person,
                                "mobile_no"         : seller_info.mobile_no,
                                "telephone"         : seller_info.telephone,
                                "e_mail"            : seller_info.e_mail,
                                "first_contact_date": seller_info.first_contact_date,
                                "convo_method"      : seller_info.convo_method,
                                "vpn_location"      : seller_info.vpn_location,
                                "script"            : seller_info.script,
                                "address"           : seller_info.address,
                                "country"           : seller_info.country,
                                "region"            : seller_info.region,
                                "ship_to_us"        : seller_info.ship_to_us,
                                "notes"             : seller_info.notes,
                                "transact_history" : this.refs.transact_history
                        }
                    }
                }}

                // console.log(listing_info)
                if (this.$route.query.action == "add") {
                    listing_info.listing_id = this.$store.state.listing_id
                }

                let listing_data = {"listings":{
                    [listing_info.listing_id]:{
                        "seller_id":seller_info.seller_id,
                        "url"          : listing_info.url.url.trim(),
                        "keyword"      : listing_info.keyword,
                        "claim_type_id": listing_info.claim_type_id,
                        "platform_id":listing_info.listing_platform,
                        "listing_number":listing_info.listing_number,
                        "info"         : {
                            "listing_title"      : listing_info.listing_title,
                            "item_type"          : listing_info.item_type,
                            "transaction_history": listing_info.transaction_history,
                            "dummy_account"      : listing_info.dummy_account,
                            // "reasons"            : listing_info.reasons,
                            "listing_price"      : listing_info.listing_price,
                            "listing_price_max"  : listing_info.listing_price_max,
                            "currency"           : listing_info.currency,
                            "listing_price_unit" : listing_info.listing_price_unit,
                            "payment_type"       : listing_info.payment_type,
                            "logo_on_item_image" : listing_info.logo_on_item_image,
                            "brand_on_item_image": listing_info.brand_on_item_image,
                            "purchase_type"      : listing_info.purchase_type,
                            "payment_platform"   : listing_info.payment_platform,
                            "transaction_number" : listing_info.transaction_number,
                            "purchase_price"     : listing_info.purchase_price,
                            "listing_location"   : listing_info.listing_location,
                            "shipping_location"  : listing_info.shipping_location,
                            "date_captured" : this.$store.state.dateCaptured || 0
                        }
                    }
                }}

                let update = true;

                if (parseFloat(listing_info.listing_price_max) < parseFloat(listing_info.listing_price)) {
                    update = false;
                    this.showSnackbar("Maximum Price must be greater than initial price",false,'invalid','fas fa-times-circle');
                }

                //Check if there are changes in seller
                const modifiedSeller = seller_data.sellers[Object.keys(seller_data.sellers).toString()];
                const originalSeller = this.seller_reference.sellers[Object.keys(this.seller_reference.sellers).toString()];

                const hasSellerChange = Object.keys(modifiedSeller).some(key => {
                    if (key != 'info') {
                        return modifiedSeller[key] != originalSeller[key];
                    }

                    return Object.keys(modifiedSeller.info).some(key => {
                        return modifiedSeller.info[key] != originalSeller.info[key];
                    });
                });

                //Check if there are changes in listing
                const modifiedListing = listing_data.listings[Object.keys(listing_data.listings).toString()];
                const originalListing = this.listing_reference.listings[Object.keys(this.listing_reference.listings).toString()];

                const hasListingChange = Object.keys(modifiedListing).some(key => {
                    if (key != 'info') {
                        return modifiedListing[key] != originalListing[key];
                    }

                    return Object.keys(modifiedListing.info).some(key => {
                        return modifiedListing.info[key] != originalListing.info[key];
                    });
                });

                const hasMainAccount = this.refs.listingpaymentaccount.added_payments.some(s => s.is_main);

                const hasNoListingInfoReasonsAdded = this.$featureAccess.isAllowed('v-field-edit-infringement-reason') && !listing_info.reasons.length;
                const hasNoMainAccountAdded        = this.$featureAccess.isAllowed('v-field-edit-payment-accounts') && !hasMainAccount;

                if (await this.validateFieldsNotEmpty() || hasNoListingInfoReasonsAdded || hasNoMainAccountAdded) {

                    this.showSnackbar("Some required fields are empty. Fill out and try again", false, "invalid", "fas fa-exclamation-circle");
                    await sleep(100);
                    document.querySelector('.is-invalid')?.scrollIntoView({ behavior: 'smooth', block: 'center' });

                    return;
                }

                if (update) {
                    if (listing_info.reasons.length > 0) {
                        let reasons = listing_info.reasons.join(",")
                        axios.patch(process.env.VUE_APP_URL+`/reasons/${listing_info.listing_id}/${reasons}`,{},config)
                    }

                    this.showSnackbar("Updating listing information",false)
                    config.headers.listing = listing_info.listing_id

                    if (hasSellerChange) {
                        if(this.$store.state.no_seller_attached) {
                            const sellerBody       = {...seller_data.sellers[0]}
                            sellerBody.campaign_id = this.$route.params.campaign_id
                            delete sellerBody.info

                            await this.$http.post('/sellers', sellerBody, config)
                                .then(({data}) => {
                                    const {id} = data.data
                                    seller_data.sellers[id] = seller_data.sellers[0]
                                    delete seller_data.sellers[0]

                                    listing_data.listings[listing_info.listing_id].seller_id = id
                                })
                                .catch(err => {
                                    console.log(err)
                                    this.$showSnackbar('Something went wrong while adding seller', false, 'invalid', 'fas fa-times-circle')
                                })
                        }

                        await this.$http.patch('/multi', seller_data, config)
                            .then(() => {
                                if(!hasListingChange) {
                                    this.showSnackbar("Successfully update listing", false, "success", "fas fa-check-circle")
                                    if(type != "single") {
                                        setTimeout(() => {
                                            console.log(this.$route.query.lid)
                                            if(this.isCM) {
                                                this.$store.dispatch('setKeywords', '')
                                            } else {
                                                this.$store.dispatch('setKeywords', this.$route.query.lid.toString())
                                            }
                                            this.showListingInfo(false)
                                            this.showEvidence(false)
                                            this.$router.push(this.returnRoute)
                                            
                                        }, 1000);
                                    }
                                }
                            })
                            .catch(err => this.showSnackbar(err.response.data.message, false, 'invalid', 'fas fa-times-circle'))
                    }

                    // #region - updated flow of payment accounts addition - Marvin 10/19/2023
                    const sseller_id = Object.keys(seller_data.sellers)[0]
                    if (this.paymentAccountsToBeAdded.length > 0 && sseller_id) {
                        for (let index = 0; index < this.paymentAccountsToBeAdded.length; index++) {
                            const { type, name_email, number, is_main } = this.paymentAccountsToBeAdded[index]
                            let aid = 0 // account id

                            let seller_accounts = {"seller_accounts":[{ seller_id: sseller_id, campaign_id: this.$route.params.campaign_id, type, name_email, number }]}

                            this.$http.post("multi", seller_accounts, config)
                                .then(({ data }) => {
                                    aid = data[0].data.id

                                    if (is_main) {
                                        const llisting_id = Object.keys(listing_data.listings)[0]
                                        let main_account = {"listings":{[llisting_id]:{"seller_account_id": aid}}}
                                        this.$http.patch('multi', main_account, config)
                                            .catch(err => this.parent.showSnackbar(err.response.data.meta.message, false, 'invalid', 'fas fa-times-circle'))
                                    }
                                })
                                .catch(err => this.parent.showSnackbar(err.response.data.meta.message, false, 'invalid', 'fas fa-times-circle'))
                        }
                    }
                    // #endregion

                    if(hasListingChange) {
                        const list_config = JSON.parse(JSON.parse(localStorage.getItem(this.isCM ? 'lawfirm' : 'ver')).headers)

                        this.$http.patch('/multi', listing_data, list_config)
                            .then(response => {
                                this.showSnackbar("Successfully update listing", false, "success", "fas fa-check-circle")
                                if(type != "single"){
                                    setTimeout(() => {
                                        if(this.isCM) {
                                            this.$store.dispatch('setKeywords', '')
                                        } else {
                                            this.$store.dispatch('setKeywords', this.$route.query.lid.toString())
                                        }
                                        this.showListingInfo(false)
                                        this.showEvidence(false)
                                        this.$router.push(this.returnRoute)
                                        
                                    }, 1000);
                                }
                            })
                            .catch(err => {
                                if (err?.response?.data?.message) {
                                    this.showSnackbar(err.response.data.message,false,'invalid','fas fa-times-circle')
                                }
                                else if (err?.response?.data?.meta?.message) {
                                    this.showSnackbar(err.response.data.meta.message,false,'invalid','fas fa-times-circle')
                                }
                                else {
                                    this.showSnackbar("Something went wrong on our end. Please contact system admin",false,'invalid','fas fa-times-circle')
                                }
                            })
                    }

                    if (!hasListingChange && !hasSellerChange) {
                        this.showSnackbar("Successfully update listing",false,"success","fas fa-check-circle")
                        if(type != "single"){
                            setTimeout(() => {

                                if(this.isCM) {
                                    this.$store.dispatch('setKeywords', '')
                                } else {
                                    this.$store.dispatch('setKeywords', this.$route.query.lid.toString())
                                }
                                this.showListingInfo(false)
                                this.showEvidence(false)
                                this.$router.push(this.returnRoute)
                                
                            }, 1000);
                        }
                    }

                    const active_li = this.active_listing_info[0]
                    globalSend({ type: 'notification', data: { updater_id: this.user_id, researcher_id: active_li.user_id, qa_user_id: active_li.qa_user_id, } });
                }

                // Added trigger for checking if there are Clients from Listings 

                let trigger_payload = {
                    label: 'isTriggerOn_saveAndExitEditListing',
                    value: true,
                }
                await this.$store.dispatch('setTrigger', trigger_payload)
            },
            changeStatus(lid,status){
                let header = JSON.parse(JSON.parse(localStorage.getItem(this.isCM ? 'lawfirm' : 'ver')).headers)
                header.headers["Campaign-id"] = this.$route.params.campaign_id
                axios.patch(process.env.VUE_APP_URL+`/listings/${lid}/statuschange`,{qflag_id:status}, header)
                    .then(response=>{
                        if(response.data.meta.code == 200){
                            let campaign_id = this.$route.params.campaign_id
                            this.showSnackbar("Successfully change status",false,'success','fas fa-check-circle')
                            setTimeout(() => {
                                let status_text = status == 4 ? "Work in Progress" : status == 1 ? "Researched" : status == 3 ? "Qualified" : "Invalid"
                                this.$router.push({name:"listing",params:{campaign_id}, query: {status: status_text, platform: 0}})
                            },1500);
                        }
                    })
            },
            async tokenChecker() {
                if (location.pathname.includes("verification") && localStorage.getItem('ver') != null){
                    let token   = JSON.parse(localStorage.getItem('ver')).token
                    let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                    return axios.get(process.env.VUE_APP_URL+'/check_token',headers)
                    .then(response=>{
                        if(response.data.meta.code == 200){
                            setTimeout(() => {
                                this.tokenChecker()//Call itself to check token again
                            }, 300000);
                            return true;
                        }
                        return false;
                    }).catch(err=>{
                        // alert(err.response.data.meta.message+". You will be redirected to login.")
                        // this.$router.push({name:"verification"})
                        if (err.response && err.response.status == 401) {//Successful connection but UNAUTHORIZED/BLACKLISTED
                            this.sidebar_campaigns = []
                            localStorage.removeItem('ver')
                            this.$router.replace({name:"vernosession"})
                        }                        

                        return false;
                    })
                }
                if (location.pathname.includes("lawfirm") && localStorage.getItem("token") != null){
                    let token   = localStorage.getItem("token")
                    let headers = JSON.parse(localStorage.getItem("headers"))
                    return axios.get(process.env.VUE_APP_URL+'/check_token',headers)
                    .then(response=>{
                        if(response.data.meta.code == 200){
                            setTimeout(() => {
                                this.tokenChecker()//Call itself to check token again
                            }, 300000);
                            return true;
                        }
                        return false;
                    }).catch(err=>{
                        // alert(err.response.data.meta.message+". You will be redirected to login.")
                        // this.$router.push({name:"lawfirm"})
                        if (err.response && err.response.status == 401) {//Successful connection but UNAUTHORIZED/BLACKLISTED
                            this.sidebar_campaigns = []
                            localStorage.removeItem("token")
                            localStorage.removeItem("headers")
                            localStorage.removeItem("lawfirm")
                            this.$router.replace({name:"lawfirmnosession"})
                        }

                        return false;
                    })
                }
                if (location.pathname.includes("admin") && localStorage.getItem('admin') != null) {
                    let token   = JSON.parse(localStorage.getItem("admin")).token
                    let headers = JSON.parse(JSON.parse(localStorage.getItem("admin")).headers)
                    axios.get(process.env.VUE_APP_URL+'/check_token',headers)
                    .then(response=>{
                        if(response.data.meta.code == 200){
                            setTimeout(() => {
                                this.tokenChecker()//Call itself to check token again
                            }, 300000);
                            return true;
                        }
                    }).catch(err=>{
                        // alert(err.response.data.meta.message+". You will be redirected to login.")
                        // this.$router.push({name:"admin"})
                        if (err.response && err.response.status == 401) {//Successful connection but UNAUTHORIZED/BLACKLISTED
                            this.sidebar_campaigns = []
                            localStorage.removeItem('admin')
                            this.$session.remove("admin")
                            this.$session.remove("admin_headers")
                            this.$router.replace({name:"adminnosession"})
                        }
                        
                        return false;
                    })
                }
            },
            ucFirst(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            ucWords(string, delimiter = ' ') {
                if (string == undefined || string == "")
                    return string

                var words = string.trim().split(delimiter)
                var result = []

                if (words.length > 1) {
                    for(var i = 0; i < words.length; i++) {
                        result.push(this.ucFirst(words[i]))
                    }

                    return result.join(' ')
                }
                
                return this.ucFirst(words.join(''))
            },
            getImage(type, name) {
                if (name == undefined || name == "")
                    return name

                if (type == 'users') {
                    let image = ""
                    switch (name) {
                        case "QA":
                            image = 'QA'
                            break
                        case "Lawyer":
                            image = 'Lawyer'
                            break
                        case "Administrator":
                            image = 'Admin'
                            break
                        case "Campaign Lead":
                            image = 'CL'
                            break
                        case "Client":
                            image = 'Client'
                            break
                        case "Super Admin":
                            image = 'Super'
                            break
                        default:
                            image = 'Researcher'
                            break
                    }
                    return `/static/svg/User_${image}.svg`
                }
                name = name.replace(/\(.+?\)/g, ' ');
                name = name.replace(/[^a-z0-9+]+/gi, ' ');
                // return 'https://artemisapi.newalchemysolutions.com' + `/jpg/${type == "campaigns" ? "campaigns" : "platforms"}/${(this.ucWords(name)).replace(/\s/g, '')}.jpg?t=${Date.now()}`
                //Remove Date.now() to prevent image refetching

                const extension = type == 'classes' ? 'svg' : 'jpg'

                return `${process.env.VUE_APP_URL}/${extension}/${type}/${this.ucWords(name).replace(/\s/g, '')}.${extension}`
            },
            uncheckPlatforms() {
                setTimeout(() => {
                    let platforms = document.querySelectorAll('input[name=platforms]')
    
                    for (var i = 0; i < platforms.length; i++) {
                        if (platforms[i].value == 0) {
                            platforms[i].checked = true
                            continue
                        }
    
                        platforms[i].checked = false
                    }
                }, 1000);
            },
            getQFlagId(flag) {
                var qflagid = 0

                if (!flag) return qflagid

                flag = flag.toLowerCase()
                
                switch (flag) {
                    case "new":
                        qflagid = 0
                        break
                    case "researched":
                        qflagid = 1
                        break
                    case "recheck":
                    case "for rework":
                        qflagid = 2
                        break
                    case "qualified":
                    case "for review":
                        qflagid = 3
                        break
                    case "work in progress":
                        qflagid = 4
                        break
                    case "ordered":
                        qflagid = 5
                        break
                    case "delivered":
                        qflagid = 6
                        break
                    case "sent mail":
                        qflagid = 7
                        break
                    case "reworked":
                        qflagid = 8
                        break
                    case "sold out":
                        qflagid = 9
                        break
                    case "preverified":
                        qflagid = 10
                        break
                    case "reassigned":
                        qflagid = 11
                        break
                    case "hacked":
                    case "auto qualified":
                        qflagid = 30
                        break
                    case "monitoring":
                        qflagid = 31
                        break
                    case "accepted":
                        qflagid = 32
                        break
                    case "rejected":
                        qflagid = 33
                        break
                    case "filed":
                        qflagid = 34
                        break
                    case "submitted":
                        qflagid = 35
                        break
                    case "for internal review":
                        qflagid = 36
                        break
                    case "initial review completed":
                        qflagid = 37
                        break
                    case "recycled":
                        qflagid = 38
                        break
                    case "for purchasing":
                        qflagid = 41
                        break
                    case "paid":
                        qflagid = 97
                        break
                    case "has potential":
                        qflagid = 98
                        break
                    case "invalid":
                        qflagid = 99
                        break
                    case "archived":
                        qflagid = 66
                        break
                    default:
                        qflagid = 4
                        break
                }

                return qflagid
            },
            getQFlag(id) {
                var qflag = ""

                switch (parseInt(id)) {
                    case 0:
                        qflag = "New"
                        break
                    case 1:
                        qflag = "Researched"
                        break
                    case 2:
                        qflag = "Recheck"
                        break
                    case 3:
                        qflag = "Qualified"
                        break
                    case 4:
                        qflag = "Work in Progress"
                        break
                    case 5:
                        qflag = "Ordered"
                        break
                    case 6:
                        qflag = "Delivered"
                        break
                    case 7:
                        qflag = "Sent mail"
                        break
                    case 8:
                        qflag = "Reworked"
                        break
                    case 9:
                        qflag = "Sold out"
                        break
                    case 10:
                        qflag = "Preverified"
                        break
                    case 11:
                        qflag = "Reassigned"
                        break
                    case 30:
                        qflag = "Hacked"
                        break
                    case 31:
                        qflag = "Monitoring"
                        break
                    case 32:
                        qflag = "Accepted"
                        break
                    case 33:
                        qflag = "Rejected"
                        break
                    case 34:
                        qflag = "Filed"
                        break
                    case 35:
                        qflag = "Submitted"
                        break
                    case 36:
                        qflag = "For Internal Review"
                        break
                    case 37:
                        qflag = "Initial Review Completed"
                        break
                    case 38:
                        qflag = "Recycled"
                        break
                    case 41:
                        qflag = "For Purchasing"
                        break
                    case 97:
                        qflag = "Paid"
                        break
                    case 98:
                        qflag = "Has potential"
                        break
                    case 99:
                        qflag = "Invalid"
                        break
                    case 66:
                        qflag = "Archived"
                        break
                    default:
                        qflag = "Work in Progress"
                        break
                }

                return qflag
            },
            async cancelEdit() {
                if (this.$route.path.includes('/admin')) {
                    if (this.$route.path.includes('campaigns'))
                        this.$router.push({name:'admincampaigns'})
                    else if (this.$route.path.includes('lf'))
                        this.$router.push({name:'adminlawfirms'}) 
                } else if (this.$route.path.includes('/lawfirm')) {
                    if (this.isCM) {
                        let daListingInfo = this.$children.filter(c => c.$vnode.tag.includes('cm-edit-listing'))[0].$children[0]
                        let daEvidences = daListingInfo.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-listinginfo')[0].$children.filter(c => c.$vnode.componentOptions.tag == 'ar-evidence')[0].uploaded_evidences
                        let requiredEvidences = { ...daListingInfo.required_fields.Evidences }
        
                        for (let rIndex = 0; rIndex < daEvidences.length; rIndex++) {
                            const getEvidenceKey = (key) => {
                                let evidenceKey = ""
                                if (key.toLowerCase().includes('lf') && key.toLowerCase().includes('review'))
                                    evidenceKey = 'LF Review'
                                else if (key.toLowerCase().includes('preview'))
                                    evidenceKey = 'Preview'
                                else if (key.toLowerCase().includes('product'))
                                    evidenceKey = 'Product'
                                else if (key.toLowerCase().includes('store'))
                                    evidenceKey = 'Store'
        
                                return evidenceKey
                            }
                            
                            requiredEvidences[getEvidenceKey(daEvidences[rIndex].type)] = daEvidences[rIndex].image != '' && daEvidences[rIndex].image != null
                        }
        
                        let daRequired_fields = [ ...Object.entries(requiredEvidences) ]
        
                        for (let rIndex = 0; rIndex < daRequired_fields.length; rIndex++) {
                            let current_field = daRequired_fields[rIndex]
                            
                            if (!current_field[1]) {
                                this.showSnackbar(current_field[0] + " is missing, please reupload to continue.",false,'invalid','fas fa-times-circle')
                                return
                            }
                        } 
                    }
                    this.$store.dispatch('setKeywords', '')
                    this.$router.push({name:'lawfirmlisting',params:{campaign_id:this.$route.params.campaign_id, status: this.isCM && this.$route.query.qflag_id == 3 ? "For Review" : this.getQFlag(this.$route.query.qflag_id), case_id: 0 } })
                } else {
                    /**
                     * Deliverable: Remove listing id from filter after edit listing
                     * Deliverable Number: 465
                     */
                    this.$store.dispatch('setKeywords', '')
                    if(this.previous_route?.query?.status == 'task'){
                        this.$router.push({name: this.previous_route.name})
                    } else if(this.Listing_has_clientListings && this.access_level == 1) {
                        this.$router.push({name:"listing",params:{campaign_id:this.$route.params.campaign_id}, query: { status: 'Client Listing', platform: 0 }})
                    } else {
                        this.$router.push({name:'listing',params:{campaign_id:this.$route.params.campaign_id}, query: {status: this.previous_route?.query?.status == '' || this.previous_route?.query?.status == undefined ? this.getQFlag(this.previous_route?.query?.qflag_id) : this.previous_route?.query?.status /*this.getQFlag(this.$route.query.qflag_id)*/, platform: 0 /*,keywords:this.$route.query.lid*/}})
                    }

                    // Added trigger for checking if there are Clients from Listings 

                    let trigger_payload = {
                        label: 'isTriggerOn_cancelEditListing',
                        value: true,
                    }
                    await this.$store.dispatch('setTrigger', trigger_payload)
                }
            },
            getQFlagIcon(id, withColor = false) {
                var icon = ""
                
                switch (id) {
                    case 0:
                        icon = "/static/svg/Icon_New.svg"
                        break
                    case 1:
                        icon = "/static/svg/Icon_Researched.svg"
                        break
                    case 2:
                        icon = "/static/svg/Icon_ForRework.svg"
                        break
                    case 3:
                        icon = "/static/svg/Icon_Qualified.svg"
                        break
                    case 4:
                    case 10:
                        icon = "/static/svg/Icon_WIP.svg"
                        break
                    case 5:
                        icon = "/static/svg/Icon_Ordered.svg"
                        break
                    case 6:
                        icon = "/static/svg/Icon_Delivered.svg"
                        break
                    case 7:
                        icon = "/static/svg/Icon_SentMail.svg"
                        break
                    case 8:
                        icon = "/static/svg/Icon_Reworked.svg"
                        break
                    case 9:
                        icon = "/static/svg/Icon_SoldOut.svg"
                        break
                    // case 10:
                    //     icon = "/static/svg/Icon_Preverified.svg"
                    //     break
                    case 11:
                        icon = "/static/svg/Icon_Reassigned.svg"
                        break
                    case 30:
                        icon = "/static/svg/Icon_Hacked.svg"
                        break
                    case 31:
                        if(withColor){
                            // this is an Icon_Monitoring but orange
                            icon = "/static/svg/Orange.svg"
                        }else {
                            icon = "/static/svg/Icon_Monitoring.svg"
                        }
                        break
                    case 32:
                        if(withColor){
                            // this is an Icon_Accepted but green
                            icon = "/static/svg/Green.svg"
                        }else {
                            icon = "/static/svg/Icon_Accepted.svg"
                        }
                        break
                    case 33:
                        if(withColor){
                            // this is an Icon_Rejected but red
                            icon = "/static/svg/Red.svg"
                        }else {
                            icon = "/static/svg/Icon_Rejected.svg"
                        }
                        break
                    case 34:
                        icon = "/static/svg/Icon_Filed.svg"
                        break
                    case 35:
                        icon = "/static/svg/Icon_Submitted.svg"
                        break
                    case 36:
                        icon = "/static/svg/Icon_Review.svg"
                        break
                    case 37:
                        icon = "/static/svg/notifications/Initial Review Completed.svg"
                        break
                    case 38:
                        icon = "/static/svg/Icon_Recycled.svg"
                        break
                    case 41:
                        icon = "/static/svg/ForPurchase-Status.svg"
                        break
                    case 97:
                        icon = "/static/svg/Icon_Paid.svg"
                        break
                    case 98:
                        icon = "/static/svg/Icon_HasPotential.svg"
                        break
                    case 99:
                        icon = "/static/svg/Icon_Invalid.svg"
                        break
                    default:
                        icon = "/static/svg/Icon_Reworked.svg"
                        break
                }
                return icon
            },
            formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
                try {
                    decimalCount = Math.abs(decimalCount)
                    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

                    const negativeSign = amount < 0 ? "-" : ""

                    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
                    let j = (i.length > 3) ? i.length % 3 : 0

                    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
                } catch (e) {
                    console.log(e)
                }
            },
            base64encode(str) {
                return window.btoa(unescape(encodeURIComponent(str)))
            },
            base64decode(str) {
                return decodeURIComponent(escape(window.atob(str)))
            },
            async backToListing(){
                let id = this.$route.params.campaign_id
                // Manual Addition
                /**
                 * Redirect to Client Listing if has Client Listings
                 */
                if(this.Listing_has_clientListings) {
                    this.$router.push({name:'listing',params:{'campaign_id':id},query:{'status':'Client Listing','platform':0}})
                } else {
                    this.$router.push({name:"listing",params:{"campaign_id":id},query:{"status": "Work in Progress","platform":0}})
                }

                // Added trigger for checking if there are Clients from Listings 
                
                let trigger_payload = {
                    label: 'isTriggerOn_cancelManualAddition',
                    value: true,
                }
                await this.$store.dispatch('setTrigger', trigger_payload)
            },
            isListingsPage() {
                return this.$route.name == 'listing' || this.$route.name == 'lawfirmlisting'
            },
            isCaseListingsPage() {
                return this.$route.params.status == 'Cases' && this.$route.params.case_id == '0'
            },
            showExitMessage(){
                this.showSnackbar("Please save unsaved changes.",false,"invalid","fas fa-info-circle")
            },
            getRandomKey() {
                const shuffle = v=>[...v].sort(_=>Math.random()-.5).join('')
                return shuffle(Math.random().toString(36).substr(2, 5) + Date.now());
            },
        }
    }
</script>

<style>
    .a-container {
        position: relative;
        min-height: 100vh;
        padding-top: 0;
        width: 100%;
    }

    .a-container.grey-bg {
        background: #F6F7FB;
    }

    .a-navcon {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
    }

    .titlebar-con {
        position: fixed;
        width: 100%;
        padding-top: 56px;
        z-index: 3;
        background: #FFFFFF;
    }

    .campaigns-list {
        height: 350px;
        overflow: auto;
    }

    .welcome-trigger {
        position: fixed;
        z-index: -2;
        top: 0;
    }

    .welcome-con {
        position: relative;
        text-align: left;
        padding: 55px 327px 0 15px;
    }

    .welcome-con h5 {
        font-weight: 500 !important;
        color: #515365;
        line-height: 24px;
        font-size: 24px;
        margin-bottom: 41px;
    }

    .welcome-con h5 span {
        font-weight: 400 !important;
        color: #383A3D;
        font-size: 16px;
        display: block;
        margin-top: 28px;
    }

    .welcome-con h5 strong {
        font-weight: 400 !important;
    }

    .welcome-con img {
        position: absolute;
        right: 20px;
        top: 60%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .a-navbar-nav button.btn-qualified {
        color: #29ABA2;
        border-color:#29ABA2;
    }

    .a-navbar-nav button.btn-qualified:hover {
        color: #ffff;
        background: #29ABA2;
    }

    .line-through {
        text-decoration: line-through;
    }

    .cursor-pointer {
        cursor: pointer;
    }
    /**
    *@override vue-toastification default style
    **/
    .Vue-Toastification__toast--default {
        background-color: #ffffff !important;
        color: #1a1a1a !important;
    }
    .Vue-Toastification__toast--default > .Vue-Toastification__progress-bar {
        background-color: #3e88da;
    }
    .Vue-Toastification__toast--default > .Vue-Toastification__close-button {
        color: #3e88da;
    }
    .Vue-Toastification__toast {
        max-width: 450px;
    }
</style>
