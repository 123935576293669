<template>
    <div class="a-login" :class="{'in-holiday-theme': isHolidayTheme }" @click="isHolidayTheme ? playThemeAudio() : null">
        <template v-if="isHolidayTheme">
            <iframe src="/static/theme/mp3/silent-background-audio.mp3" allow="autoplay" id="silent-audio" style="display: none"></iframe>
            <audio autoplay loop id="themeAudio">
                <source src="/static/theme/mp3/theme-audio.mp3" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>
        </template>
        <div v-if="isHolidayTheme" class="splash-img">
            <img class="theme-splash-img" :src="image" alt="some sort of splash image">
        </div>
        <img v-else class="splash-img" :src="image" alt="two people smiling">

        <div class="a-login-container float-right">
            <img class="a-login-bg" v-if="!isHolidayTheme" src="/static/svg/splat.svg" alt="cloud stuff">
            <div class="a-login-form">
                <img class="a-login-logo" v-if="!isHolidayTheme" :src="logo" alt="logo">
                <img class="a-login-logo-sub" v-else :src="logo_sub" />
                <template v-if="!isHolidayTheme">
                    <h5>Artemis <span>{{gui}}</span></h5>
                </template>
                <p>Welcome back! Please sign in to start your session.</p>
                <form id="loginForm" @submit.prevent="login()">
                    <ar-textfield a_class="mb-4" placeholder="Username" v-model="user_id" id="user_id" />
                    <ar-textfield a_type="password" placeholder="Password" v-model="password" id="password" />
                    <ar-button id="submit" type="submit" @click.once="login()">Login</ar-button>
                </form>
            </div>
            <footer class="login-footer">
                Accelerated Research, Trademark Enforcement and Management Information System &copy; 2019
            </footer>
        </div>
    </div>
</template>

<script>
    import button from './Button'
    import textfield from './Textfield'
    import axios from '../../axiosMime'
    // import SnowVue from './Snow'
    import {mapActions, mapState, mapGetters} from 'vuex'
    import { initializeSocket } from '../../events';

    export default {
        components: {
            'ar-button': button,
            'ar-textfield': textfield,
            // 'snowVue': SnowVue
        },
        props: {
            image: {
                default: "/static/svg/Design.svg"
            },
            gui: {
                default: ""
            },
            redirect: {
                default: ""
            },
            logo: {
                default: "/static/svg/Logo.svg"
            },
            logo_sub: {
                default: "",
            }
        },
        data() {
            return {
                link: this.redirect,
                user_id: '',
                password: '',
            }
        },
        async mounted(){
            if(!localStorage.getItem("lawfirm") && !localStorage.getItem("ver") && !localStorage.getItem("admin")){
                this.clearNotifications();
            }

            if (this.isHolidayTheme)
                this.initializeThemeAudio()
        },
        computed: {
            ...mapState(['themeAudio']),
            ...mapGetters(['isHolidayTheme'])
        },
        methods: {
            ...mapActions(['startNotificationListener', 'getNotifications', 'clearNotifications', 'initializeThemeAudio', 'stopThemeAudio', 'getThemePathname']),
            playThemeAudio() {
                return this.isHolidayTheme ? themeAudio.play() : null
            },
            async login() {
                document.getElementById('submit').disabled = true;

                var user_id = this.user_id
                var password = this.password
                var redirect = this.link

                this.$parent.$parent.showSnackbar("Authenticating user..", true)

                await axios.post(process.env.VUE_APP_URL + "/login", {
                        user_id,
                        password
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            "Gui" : this.gui
                        }
                    })
                    .then(response => {
                        if (response.status == 200 && 'access_token' in response.data.meta) {
                            this.stopThemeAudio();
                            this.getThemePathname();
                            let results = response.data
                            let now     = new Date().getTime()
                            let token   = response.data.meta.access_token
                            let headers = JSON.stringify({headers:{"Content-Type":"application/json", "Authorization":`Bearer ${token}`,"Gui": this.gui}})

                            // console.log(results.data.access_level)
                            if(results.data.access_level != null){
                                // Connect to websocket
                                initializeSocket();

                                if(this.gui == "Verification"){
                                    /* if ([1,2,5,6,7].includes(results.data.access_level)) { */

                                        if (results.data.isRestrictedToGUI) {
                                            document.getElementById('submit').disabled = false;
                                            this.$parent.$parent.showSnackbar('Your account doesn\'t have access to this site.', false, 'invalid', 'fas fa-times-circle')

                                            return;
                                        }

                                        // localStorage.setItem('gui',"Verification")
                                        localStorage.setItem("ver",JSON.stringify({...results.data,token,headers}))

                                        this.$session.set("ver", JSON.stringify({...results.data,token,headers}))
                                        this.$session.set("ver_headers",JSON.parse(headers))
                                        
                                        this.$parent.$parent.snackbar.text = ""
                                        this.$router.push(redirect)
                                        this.getNotifications();
                                        this.startNotificationListener();

                                    /* } else {
                                        document.getElementById('submit').disabled = false
                                        this.$parent.$parent.showSnackbar("Your account doesn't have access to this site", false, "invalid", "fas fa-times-circle")
                                    } */
                                }else if (this.gui == "Case Management"){
                                    /* if ([3,6,7].includes(results.data.access_level)) { */

                                        if (results.data.isRestrictedToGUI) {
                                            document.getElementById('submit').disabled = false;
                                            this.$parent.$parent.showSnackbar('Your account doesn\'t have access to this site.', false, 'invalid', 'fas fa-times-circle')

                                            return;
                                        }

                                        localStorage.setItem('gui',"Lawfirm")
                                        localStorage.setItem('token',token)
                                        localStorage.setItem('headers',headers)
                                        for(let x in results.data){
                                            localStorage.setItem(x,results.data[x])
                                        }
                                        localStorage.setItem("lawfirm",JSON.stringify({...results.data,token,headers}))


                                        this.$session.set("lawfirm", JSON.stringify({...results.data,token,headers}))
                                        this.$session.set("lawfirm_headers",JSON.parse(headers))

                                        this.$parent.$parent.snackbar.text = ""
                                        if(results.data.access_level == 6) {
                                            this.$router.push(redirect)
                                        }
                                        else{
                                            this.$router.push('lawfirmdashboard')
                                        }
                                        // this.$router.push(redirect)
                                        // this.getNotifications();
                                        // this.startNotificationListener();
                                    /* } else {
                                        document.getElementById('submit').disabled = false
                                        this.$parent.$parent.showSnackbar("Your account doesn't have access to this site", false, "invalid", "fas fa-times-circle")
                                    } */
                                } else if (this.gui == "Admin") {
                                    /* if ([4,5,7].includes(results.data.access_level)) { */

                                        if (results.data.isRestrictedToGUI) {
                                            document.getElementById('submit').disabled = false;
                                            this.$parent.$parent.showSnackbar('Your account doesn\'t have access to this site.', false, 'invalid', 'fas fa-times-circle')

                                            return;
                                        }

                                        localStorage.setItem("admin",JSON.stringify({...results.data,token,headers}))

                                        this.$session.set("admin", JSON.stringify({...results.data,token,headers}))
                                        this.$session.set("admin_headers",JSON.parse(headers))

                                        this.$parent.$parent.snackbar.text = ""
                                        this.$router.push(redirect)
                                        // this.getNotifications();
                                        // this.startNotificationListener();
                                    /* } else {
                                        document.getElementById('submit').disabled = false
                                        this.$parent.$parent.showSnackbar("Your account doesn't have access to this site", false, "invalid", "fas fa-times-circle")
                                    } */
                                }
                                // location.reload()
                            }else{
                                this.$parent.$parent.showSnackbar("Your account doesn't have access to this site",false,"invalid","fas fa-times-circle")
                                document.getElementById('submit').disabled = false
                            }
                           
                            window.location.reload()
                            // location.reload()
                        } else {
                            // alert(`${err.response.data.meta.message}. Please contact system admin.`)
                            document.getElementById('submit').disabled = false
                            this.$parent.$parent.showSnackbar(response.data.meta.message, false, "invalid", "fas fa-times-circle")
                        }
                    })
                    .catch(err => {
                        // alert(`${err.response.data.meta.message}. Please contact system admin.`)
                        console.log(err)
                        document.getElementById('submit').disabled = false
                        this.$parent.$parent.showSnackbar(err.response.data.meta.message, false, "invalid", "fas fa-times-circle")
                    })

                this.$parent.$parent.navbarKey = this.getRandomKey()
            },
            getRandomKey() {
                const shuffle = v=>[...v].sort(_=>Math.random()-.5).join('')
                return shuffle(Math.random().toString(36).substr(2, 5) + Date.now());
            },
        }
    }
</script>

<style scoped>
    .a-login {
        position: relative;
        width   : 100%;
        height  : 100vh;
    }

    .a-login .splash-img {
        position: absolute;
        bottom  : 46px;
        left    : 15px;
        width   : 45%;
    }

    .a-login-container {
        position: relative;
        height  : 100%;
        width   : 57%;
        z-index : 2;
    }

    .a-login-bg {
        position: absolute;
        z-index : -1;
        width   : 100%;
        right   : 0;
        top     : 0;
    }

    .a-login-form {
        text-align       : center;
        position         : absolute;
        -webkit-transform: translate(-50%, -50%);
        transform        : translate(-50%, -50%);
        max-width        : 100%;
        width            : 400px;
        left             : 56%;
        top              : 38%;
        z-index          : 1;
    }

    .a-login-form h5 {
        color         : #3E88DA;
        font-size     : 32px;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-weight   : 700;
        margin-bottom : 12px;
    }

    .gui-admin .a-login-form h5 {
        color : #480048;
    }

    .a-login-form h5 span {
        position      : relative;
        font-size     : 12px;
        color         : #141517;
        letter-spacing: 2.4px;
        left          : -10px;
        top           : -2px;
        font-weight   : 500;
    }

    .a-login-form p {
        margin-bottom: 38px;
        color        : #a1a2aa;
        font-size    : 16px;
    }

    .a-btn {
        width: 150px;
    }

    .a-textfield {
        max-width: 100%;
        width    : 326px;
        display  : block;
        margin   : 0 auto 20px;
    }

    .login-footer {
        letter-spacing: 0.21px;
        position      : absolute;
        padding-left  : 51px;
        text-align    : center;
        color         : #515365;
        font-size     : 12px;
        bottom        : 50px;
        width         : 100%;
    }

    .in-holiday-theme .splash-img {
        height: 100vh;
        width: calc(100% - 593px);
        overflow : hidden;
        position: fixed;
        left: 0;
        top: 0;
    }

    .in-holiday-theme .splash-img .theme-splash-img {
        width: 100%; 
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    .in-holiday-theme .a-login-container {
        width: 593px;
        background: rgba(255, 255, 255, 0.6);
    }

    .a-login-logo {
        width: 120px;
        margin-bottom: 20px;
    }

    .in-holiday-theme .a-login-logo {
        width: 100%;
    }

    .in-holiday-theme .a-login-logo-sub {
        position: absolute;
        top: 232px;
        left: 71px;
        right: 0;
        display: inline-block;
        margin: 0 auto;
        width: 230px;
    }

    .in-holiday-theme .a-login-logo-sub {
        top: -125px;
        left: 0;
        width: 100%;
    }

    .in-holiday-theme .a-login-form {
        left: 50%;
        width: 100%;
    }

    .in-holiday-theme .a-login-form {
        padding-top: 307px;
        top: 48%;
    }

    .in-holiday-theme .a-login-form .a-btn {
        width: 326px;
        margin-top: 5px;
    }

    .in-holiday-theme .login-footer {
        font-size: 10px;
        font-style: italic;
        padding: 0;
        bottom: 15px;
    }

    @media (max-width: 1560px) { 
        .in-holiday-theme .splash-img .theme-splash-img {
            width: 1000px;
        }
    }

    @media (max-width: 768px) {
        .in-holiday-theme .a-login-container {
            width: 100%;
            float: none !important;
        }

        .in-holiday-theme .a-login-form {
            padding-top: 0;
        }

        .in-holiday-theme .splash-img {
            display: none;
        }

        .in-holiday-theme .a-login-logo-sub {
            max-width: 552px;
            position: static;
        }
    }
</style>
